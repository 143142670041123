//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-564:_3072,_6060,_6508,_6916,_7900,_2284,_9592,_3052;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-564')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-564', "_3072,_6060,_6508,_6916,_7900,_2284,_9592,_3052");
        }
      }catch (ex) {
        console.error(ex);
      }
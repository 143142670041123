//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-564:_5384,_4416,_4708,_6088,_472,_7844,_1152,_7220;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-564')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-564', "_5384,_4416,_4708,_6088,_472,_7844,_1152,_7220");
        }
      }catch (ex) {
        console.error(ex);
      }